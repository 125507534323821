import React from "react";
import { StaticQuery, graphql } from "gatsby";

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={({ teamPage }) => {
      return children({
        teamPage,
      });
    }}
  />
);
const query = graphql`
  query TeamPage {
    teamPage: sanityTeamPage {
      pageTitle
      _rawClosingCopy
      members {
        ... on SanityTeamMember {
          _key
          _type
          firstName
          lastName
          image {
            ...SanityImage
            alt
          }
          jobTitle
          flavor
        }
        ... on SanityTextBrandCard {
          _key
          _type
          disabled
          backgroundColor
          parallax
          parallaxSpeedMultiplier
          verticalCenter
          textColor
          textBlocks: _rawTextBlocks(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityImageBrandCard {
          _key
          _type
          disabled
          backgroundColor
          parallax
          parallaxSpeedMultiplier
          fullbleed
          image {
            asset {
              _id
            }
          }
        }
        ... on SanityLottieBrandCard {
          _key
          _type
          disabled
          backgroundColor
          parallax
          parallaxSpeedMultiplier
          loop
          lottieFile {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;
